import EntityForm from "@/mixins/EntityForm";
import Component, {
  mixins
} from "vue-class-component";

@Component({
  inject: {
    languageService: "languageService",
    eventService: "eventService",
    userService: "userService",
  },
})
export default class InfoPointForm extends mixins(EntityForm) {
  model = {};
  internal_events = [];
  internal_speakers = [];
  get elements() {
    return [{
        id: "idEvent",
        label: this.translations.labels.events,
        type: "select",
        items: this.events,
        required: true,
        returnObject: false,
      },
      {
        id: "code",
        label: this.translations.labels.info_point_form_code,
        type: "text",
        required: true,
        rules: "min:4",
        onAction: this.noSpaces,
      },
      {
        id: "localizations",
        type: "localizations",
        header: this.translations.labels.form_localizations,
        languages: this.languages,
        resetColumns: true,
        elements: [{
            id: "imageUri",
            label: this.translations.labels.info_point_form_backgroundImage,
            type: "media",
            mediaType: "background",
            required: true,
          },
          //TODO: Remove
          {
            id: "contacts",
            label: this.translations.labels.info_point_form_contacts,
            type: "wysiwyg",
            required: false,
            resetColumns: true,
          }
        ],
      },
    ];
  }

  noSpaces(evt) {
    let charCode = evt.charCode;
    if (charCode === 32) evt.preventDefault();
    return true;
  }

  get event () {
    const _event = this.internal_events.filter(
      (e) => {
        return e.id == this.model.idEvent;
      }
    );
    if (_event.length > 0) {
      return _event[0];
    }
    return null;
  }

  get helpLink() {
    return this.translations.pageHelp.infoPoint;
  }

  get events() {
    return this.internal_events.map((i) => {
      return {
        languages: i.languages,
        label: i.code,
        value: i.id,
      };
    });
  }

  get speakers() {
    return this.internal_speakers.map((i) => {
      return {
        label: i.firstName + " " + i.lastName,
        value: i.id,
      };
    });
  }

  get languages() {
    if (
      this.model.idEvent === undefined ||
      this.model.idEvent === null ||
      this.events === undefined ||
      this.events === null
    )
      return [];
    const event = this.events.filter((e) => e.value == this.model.idEvent)[0];
    if (event != undefined) return event.languages;
    return [];
  }

  // eslint-disable-next-line no-empty-function
  afterCreate() {}

  async created() {
    this.internal_events = await this.eventService.list();
    this.internal_speakers = await this.userService.speakers();
    this.model.event = this.events.filter(
      (e) => e.value == this.model.idEvent
    )[0];

    this.afterCreate();
  }
}