<script>
import InfoPointForm from "@/views/menu/infoPoint/infoPointForm";
import Component, { mixins } from "vue-class-component";
import { Utility } from "@/lib/Utility";

@Component({
  inject: {
    infoDeskService: "infoDeskService",
    fileUploadService: "fileUploadService",
  },
})
export default class InfoPointEdit extends mixins(InfoPointForm) {
  formId = "detail-infoPoint-form";

  get decoratedElements() {
    return this.insertAfter(this.allReadonlyFields, {
      id: "entityLink",
      label: this.translations.labels.common_form_entity_link,
      type: "text",
      readonly: true,
      copyToClipboard: true,
    });
  }

  get fieldColumns() {
    return 2;
  }

  getFormTitle() {
    return this.translations.pageTitles.infoPoint_detail;
  }

  async afterCreate() {
    this.model = await this.infoDeskService.read(this.$route.params.id);
    this.model.entityLink = "Not yet generated";
    this.isReady = true;

    this.model.entityLink = Utility.generateEntityLink(this?.event, "InfoDesk", this.model.id);
  }
}
</script>
